import { EventSourcePolyfill } from "event-source-polyfill";
import appConfig from "@/app.config";

const token = "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOjEwLCJwYWdlcyI6WyIxIiwiMiJdLCJyb2xlIjoiYXBpIiwiYnVzaW5lc3MiOiIyIiwiaWF0IjoxNjU1NjEzMDQwLCJleHAiOjMxNzIwMDA1NTQ0MH0.kNN22XLDplakSlXUG-OVuM3vu7OgvGgHScpeH0k-7yk";
var es = new EventSourcePolyfill(`${appConfig.api.baseURLsmms}sse/msg`, {
  headers: {
    Accept: "application/json",
    "Content-Type": "application/x-www-form-urlencoded",
    authorization: token,
  },
});
export default es;
