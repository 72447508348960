<script
  type="text/javascript"
  src="https://api.longdo.com/map/?key=f77a3947b63bbf9cef1850dfdf0fabd6"
></script>
<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import { chatData, chatMessagesData, groupData } from "./data";
import appConfig from "@/app.config";
import Swal from "sweetalert2";
import useNetw from "@useNetw";
import simplebar from "simplebar-vue";
// import InfiniteLoading from "vue-infinite-loading";
import Multiselect from "vue-multiselect";
import DatePicker from "vue2-datepicker";
import imgChatComponent from "@/components/imgChatComponent";
import axios from "axios";
import eventSource from "../../../eventSource/index";
/**
 * Profile component
 */
export default {
  components: {
    Layout,
    PageHeader,
    Multiselect,
    simplebar,
    DatePicker,
    imgChatComponent,
  },
  page: {
    title: "ติดต่อลูกค้าโซเชียล",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  data() {
    return {
      title: "ติดต่อลูกค้าโซเชียล",
      items: [
        {
          text: "ลูกค้าสัมพันธ์",
          active: true,
        },
        {
          text: "ติดต่อลูกค้าโซเชียล",
          active: false,
        },
      ],
      form: {
        message: "",
      },
      member: null,
      username: "Designers",
      chatData: chatData,
      groupData: groupData,
      chatMessagesData: chatMessagesData,
      rowsMessage: [],
      localData: "",
      localDataBranch: "",
      localDataBranchId: "",
      rowsPage: "",
      rowsBranchPages: [],
      rowsMessageLine: [],
      page: 1,
      pageLine: 1,
      type: "FB",
      pagesId: "",
      rowsSrc: [],
      cusSrcId: "",
      allImg: [],
      allPages: "",
      visible: false,
      index: 0,
      isLoading: false,
      show: false,
      userName: "",
      imgs: [
        require("@/assets/images/small/img-1.jpg"),
        require("@/assets/images/small/img-2.jpg"),
        require("@/assets/images/small/img-3.jpg"),
        require("@/assets/images/small/img-4.jpg"),
        require("@/assets/images/small/img-5.jpg"),
        require("@/assets/images/small/img-6.jpg"),
      ],
      filter: {
        branchId: "",
        typeMessage: "",
      },
      optionTypeMessage: [
        {
          id: 1,
          type: "ติดต่อสอบถาม",
          code: "I",
        },
        {
          id: 2,
          type: "สนใจซื้อรถ",
          code: "B",
        },
        {
          id: 3,
          type: "ร้องเรียน",
          code: "C",
        },
      ],
      optionBranch: [],
      fromDate: "",
      toDate: "",
      nowDate: "",
      // localData:[],
      branchIdArr: [],
      rowsAccount: [],
      branchId: "",
      loading: false,
      pageName: "",
      urlImage: "",
      apiKeyAccess: "",
      imgLink:
        "https://lh3.googleusercontent.com/-JM2xsdjz2Bw/AAAAAAAAAAI/AAAAAAAAAAA/DVECr-jVlk4/photo.jpg",
    };
  },
  mounted() {
    const apikey = localStorage.getItem("apiKey");
    this.apiKeyAccess = apikey;
    // var map = new longdo.Map({
    //   placeholder: document.getElementById("map"),
    //   zoom: 6,
    // });

    // map.location(longdo.LocationMode.Geolocation);
    // this.getData();
    // const user = JSON.parse(localStorage.getItem(appConfig.storageUserKey));
    // const arrayBranch = [];
    // user.userAccessBranch.forEach((item) => {
    //   arrayBranch.push(item.branchId);
    // });
    // this.optionBranch = user.userAccessBranch;
    // this.filter.branchId = [user.userAccessBranch[0]];
    // this.localData = user.userAccessBranch;
    // this.getDataAccount();
    // this.getDataPage();
    // this.getDataSrc();
    this.getAllPages();
    this.getMsg();
  },
  created() {
    this.getLocalData();
    this.getNow();

    // const datep = "5/26/2022 11:17:31 PM"
    // const  datey = new Date(datep);
    // const dateyo  = `${datey.getMonth()+ 1}/${datey.getDate()}/${datey.getFullYear()}`;

    // const date  = new Date().toLocaleDateString()
    // const  datex = new Date(date);
    // const datexo  = `${datex.getMonth()+ 1}/${datex.getDate()}/${datex.getFullYear()}`;

    // if(datexo == dateyo){
    // }else{
    // }
  },
  methods: {
    showMap(data) {
      setTimeout(() => {
        var map = new longdo.Map({
          placeholder: document.getElementById("map"),
          zoom: 16,
        });
        var marker = new longdo.Marker(data);
        map.Overlays.add(marker);
        map.location(data);
      }, 500);
    },
    showImg(index, indexfile) {
      // if (this.urlFullsize) {
      //   this.getDataImageFull(data);
      // }
      this.index = indexfile;
      this.visible = true;

      // this.allImg = this.rowsMessage[index].attachments[indexfile].payload.url
      const arr = [];
      this.rowsMessage[index].attachments.forEach((item) => {
        arr.push(item.payload.url);
      });
      this.allImg = arr;
      // console.log(this.allImg)

      // this.rowsMessage.forEach((item) => {
      //   if (item.attachments != null) {
      //     item.attachments.forEach((element) => {
      //       if (element.type === "image") {
      //         this.allImg.push(element.payload.url);
      //       }
      //     });
      //   }
      // });
      // console.log(this.rowsMessage[index].attachments[indexfile].payload.url);
    },
    handleHide() {
      this.visible = false;
    },
    getNow() {
      const today = new Date();
      const date =
        today.getFullYear() +
        "-" +
        (today.getMonth() + 1 > 9 ? "" : "0") +
        (today.getMonth() + 1) +
        "-" +
        (today.getDate() > 9 ? "" : "0") +
        today.getDate();

      this.fromDate = date;
      this.toDate = date;
      this.nowDate = date;
    },
    formatDate(timestamp) {
      var date = new Date(parseInt(timestamp));
      var dd = date.getDate();
      var month = "/" + (date.getMonth() + 1);
      var fullyear = "/" + date.getFullYear();
      var hours = date.getHours();
      var minutes = "0" + date.getMinutes();
      var seconds = "0" + date.getSeconds();
      var formattedTime =
        dd +
        month +
        fullyear +
        " " +
        hours +
        ":" +
        minutes.substr(-2) +
        ":" +
        seconds.substr(-2);
      return formattedTime;
      // console.log(date.toLocaleString("th-TH", { timeZone: "UTC" }));
      // console.log(date)

      // var dateString =
      //   date.getFullYear() +
      //   "/" +
      //   ("0" + (date.getMonth() + 1)).slice(-2) +
      //   "/" +
      //   ("0" + date.getDate()).slice(-2) +
      //   " " +
      //   ("0" + date.getHours()).slice(-2) +
      //   ":" +
      //   ("0" + date.getMinutes()).slice(-2) +
      //   ":" +
      //   ("0" + date.getSeconds()).slice(-2);
      // console.log(dateString);
    },
    limitText(count) {
      return `and ${count} other Branch`;
    },
    setDefult(e) {
      e.target.src = this.imgLink;
    },
    getLocalData() {
      const user = JSON.parse(localStorage.getItem(appConfig.storageUserKey));
      const arrayBranch = [];
      user.userAccessBranch.forEach((item) => {
        arrayBranch.push(item.branchId);
      });
      this.optionBranch = user.userAccessBranch;
      this.filter.branchId = user.userAccessBranch;
      this.localData = user.userAccessBranch;
    },
    getdataHader() {
      this.getDataLine();
      // this.getData();
    },

    checkUn(data) {
      let result = "";
      if (data == "undefined") {
        result = "ส่งสติกเกอร์";
      } else {
        result = data;
      }
      return result;
    },

    getMsg() {
      eventSource.onmessage = (event) => {
        const data = JSON.parse(event.data);
        console.log(data);
        if (this.userName === data.msg.psId) {
          this.rowsMessage.unshift(data.msg);
        }
        // if (data) {
        // var datainfo = "";
        this.rowsBranchPages.forEach((item, index) => {
          // console.log(item, 'item');

          if (data.msg.pageId === parseInt(item.pageId)) {
            // if (data.msg.psId != this.userName) {
            var arr = "";
            item.users.forEach((ele) => {
              arr = ele.psId;
            });
            Math.max(arr);
            // console.log(arr)
            if (data.msg.psId > arr) {
              item.users.push({
                psId: data.msg.psId,
                name: "Unknown",
                familyName: "User",
                profileImage: null,
              });
            }
            // });
            // }
            // datainfo = item.users;
            // item.users.forEach((ele) => {
            //   if (data.msg.psId != ele.psId) {
            //     datass = {
            //       psId: data.msg.psId,
            //       name: "Unknown",
            //       familyName: "User",
            //       profileImage: null,
            //     };
            //     console.log(this.rowsBranchPages[index].users);
            //   }
            // });
            // var arr = "";
            // arr = [
            //   ...this.rowsBranchPages[index].users,
            //   {
            //     psId: data.msg.psId,
            //     name: "Unknown",
            //     familyName: "User",
            //     profileImage: null,
            //   },
            // ];
            // item.users.forEach((ele) => {
            //   if (data.msg.psId != ele.psId) {
            //     arr.forEach((info) => {
            //       info.users.forEach((customer) => {
            //         if (data.msg.psId !== customer.psId) {
            //           this.rowsBranchPages[index].users = arr;
            //         }
            //       });
            //       // console.log(info, 'info')
            //       // if (data.msg.psId !== info.psId) {
            //       //   this.rowsBranchPages[index].users = arr;
            //       // }
            //     });
            //   }
            // });
          }
        });
        // }
        // this.rowsMessage.push(data.msg);
      };
    },

    getUserMessage(user, status) {
      this.userName = user.psId;
      this.show = status;
      var token =
        "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOjEwLCJwYWdlcyI6WyIxIiwiMiJdLCJyb2xlIjoiYXBpIiwiYnVzaW5lc3MiOiIyIiwiaWF0IjoxNjU1NjEzMDQwLCJleHAiOjMxNzIwMDA1NTQ0MH0.kNN22XLDplakSlXUG-OVuM3vu7OgvGgHScpeH0k-7yk";
      // if (token) {
      axios
        .get(`${appConfig.api.baseURLsmms}api/find-user-messages`, {
          headers: {
            Authorization: `Bearer ${this.apiKeyAccess}`,
          },
          params: {
            psId: user.psId,
            startDate: this.fromDate,
            endDate: this.toDate,
            platform: "FB",
          },
        })
        .then((response) => {
          this.rowsMessage = response.data;
          if (response.data) {
            var data = "";
            response.data.forEach((item) => {
              if (item.attachments) {
                item.attachments.forEach((element) => {
                  if (element.type === "location") {
                    data = {
                      lat: element.payload.coordinates.lat,
                      lon: element.payload.coordinates.long,
                    };
                  }
                });
              }
            });
          }
          if (data != "") {
            this.showMap(data);
          }
          // }
          // response.data.forEach((item) => {
          //   if (item.attachments != null) {
          //     item.attachments.forEach((element) => {
          //       if (element.type === "image") {
          //         this.allImg.push(element.payload.url);
          //       }
          //     });
          //   }
          // });
          // console.log(this.allImg);
        });
      // .catch((err) => {
      //   Swal.fire(
      //     appConfig.swal.title.error,
      //     JSON.stringify(err),
      //     appConfig.swal.type.error
      //   );
      // })
      // .then(() => {
      //   //   this.overlayFlag = false;
      // });
      // }
    },

    getAllPages() {
      // if (token) {
      if (this.apiKeyAccess) {
        axios
          .get(`${appConfig.api.baseURLsmms}api/all-pages`, {
            headers: {
              Authorization: `Bearer ${this.apiKeyAccess}`,
            },
          })
          .then((response) => {
            if (response.data.pages) {
              const arr = [];
              response.data.pages.forEach((item) => {
                arr.push(item.pageId);
              });
              this.allPages = arr;
              this.findPages();
            }
          })
          .catch((err) => {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(err.response),
              appConfig.swal.type.error
            );
          })
          .then(() => {
            //   this.overlayFlag = false;
          });
      } else {
        Swal.fire(
          appConfig.swal.title.error,
          JSON.stringify("ท่านไม่มี API Key กรุณาตั้งค่าโซเชียลมีเดียก่อน !"),
          appConfig.swal.type.error
        );
      }
      // }
    },
    findPages() {
      // if (token) {
      axios
        .get(`${appConfig.api.baseURLsmms}api/find-pages`, {
          headers: {
            Authorization: `Bearer ${this.apiKeyAccess}`,
          },
          params: {
            pageId: this.allPages,
            startDate: this.fromDate,
            endDate: this.toDate,
            platform: "FB",
          },
        })
        .then((response) => {
          this.rowsBranchPages = response.data;
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.response),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          //   this.overlayFlag = false;
        });
      // }
    },

    // getData(psid, pageId) {
    //   this.loading = true;
    //   useNetw
    //     .get("api/social-contact/fb", {
    //       params: {
    //         page: this.page,
    //         perPage: 50,
    //         fromDate: this.fromDate,

    //         toDate: this.toDate,
    //         senderId: psid,
    //         recipient: psid,
    //         pageId: pageId,
    //       },
    //     })
    //     .then((response) => {
    //       this.rowsMessage = response.data.data;
    //       var arr = [];
    //       this.rowsMessage.forEach((element) => {
    //         arr.push(element.pageName);
    //       });
    //       var arrrrr = arr.filter(this.onlyUnique);
    //       this.rowsPage = arrrrr;
    //     })
    //     .catch((err) => {
    //       Swal.fire(
    //         appConfig.swal.title.error,
    //         JSON.stringify(err.response.data.message),
    //         appConfig.swal.type.error
    //       );
    //       // this.lastDate = this.toDate
    //     })
    //     .then(() => {
    //       this.loading = false;
    //       // this.isHidden = true;
    //     });
    // },

    getDataSrc(account, branch) {
      useNetw
        .get("api/prospect-customer/branch-customer-source", {
          params: {
            page: this.page,
            perPage: 10,
            branchId: branch,
          },
        })
        .then((response) => {
          this.rowsSrc = response.data.data;
          // var srcId;
          this.rowsSrc.forEach((item) => {
            if (
              this.type === "FB" &&
              (item.nameEn == "facebook" ||
                item.nameEn == "Facebook" ||
                item.nameTh == "facebook" ||
                item.nameTh == "Facebook" ||
                item.nameTh == "เฟซบุ๊ก")
            ) {
              this.cusSrcId = item.cusSrcId;
              this.addProspectFb(account, branch, this.cusSrcId);
            }
            if (
              this.type === "LINE" &&
              (item.nameEn == "Line" ||
                item.nameEn == "LINE" ||
                item.nameTh == "Line" ||
                item.nameTh == "LINE" ||
                item.nameEn == "ไลน์")
            ) {
              this.cusSrcId = item.cusSrcId;
              this.addProspectFb(account, branch, this.cusSrcId);
            }
          });
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.response.data.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          // this.loading = false;
          // this.isHidden = true;
        });
    },
    getAndAddPros(account, branchId) {
      this.getDataSrc(account, branchId);
    },

    addProspectFb(account, branchId, cusSrcId) {
      const bodyFormData = new FormData();
      if (this.type === "LINE") {
        bodyFormData.append(
          "branchId",
          branchId != null ? branchId : undefined
        );
        bodyFormData.append("nameTh", account.firstName);
        bodyFormData.append(
          "familyNameTh",
          !account.familyNameTh ? "" : account.familyNameTh
        );
        bodyFormData.append("cusSrcId", cusSrcId);
        bodyFormData.append("level", 3);
        bodyFormData.append("socialId", account.accountId);
        // bodyFormData.append("facebookId", account.psId);
        bodyFormData.append("readiness", 3);
      } else {
        bodyFormData.append(
          "branchId",
          branchId != null ? branchId : undefined
        );
        bodyFormData.append("nameTh", account.firstName);
        bodyFormData.append(
          "familyNameTh",
          !account.familyNameTh ? "" : account.familyNameTh
        );
        bodyFormData.append("cusSrcId", cusSrcId);
        bodyFormData.append("level", 3);
        // bodyFormData.append("lineId", account.psId);
        // bodyFormData.append("facebookId", account.psId);
        bodyFormData.append("socialId", account.accountId);
        bodyFormData.append("readiness", 3);
      }

      useNetw
        .post("api/social-contact/store", bodyFormData)
        .then((response) => {
          Swal.fire(
            appConfig.swal.title.postSuccess,
            JSON.stringify(response.data.message),
            appConfig.swal.type.success
          );
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.response.data.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          // this.$refs.modalService.hide();
          // this.overlayFlag = false; //skeleton true
        });
    },
    chatGroup(name, memberno, member) {
      this.username = name;
      this.member = memberno;
      this.usermessage = "Hello";
      this.chatMessagesData = [];
      const currentDate = new Date();

      this.chatMessagesData.push({
        name: member,
        message: this.usermessage,
        time: currentDate.getHours() + ":" + currentDate.getMinutes(),
      });
    },

    // addProspectLine(account) {
    //   // this.overlayFlag = true; //skeleton true
    //   useNetw
    //     .post("api/social-contact/store", {
    //       branchId:
    //         this.filter.branchId != null
    //           ? this.filter.branchId.branchId
    //           : undefined,
    //       nameTh: account.firstname,
    //       familyNameTh: account.lastname,
    //       cusSrcId: this.cusSrcId,
    //       level: 3,
    //       lineId: account.sender,
    //       readiness: 3,
    //     })
    //     .then((response) => {
    //       Swal.fire(
    //         appConfig.swal.title.postSuccess,
    //         JSON.stringify(response.data.message),
    //         appConfig.swal.type.success
    //       );
    //     })
    //     .catch((err) => {
    //       Swal.fire(
    //         appConfig.swal.title.error,
    //         JSON.stringify(err.response.data.message),
    //         appConfig.swal.type.error
    //       );
    //     })
    //     .then(() => {
    //       // this.$refs.modalService.hide();
    //       // this.overlayFlag = false; //skeleton true
    //     });
    // },
    // logPageId(page) {
    // },

    // getDataAccount(pagesId, pageName) {
    //   // this.loading = true;
    //   (this.pagesId = pagesId),
    //     (this.pageName = pageName),
    //     useNetw
    //       .get("api/social-contact/fb-account", {
    //         params: {
    //           page: this.page,
    //           perPage: 100,
    //           type: this.type == null ? "FB" : this.type,
    //           "branchId[]":
    //             this.filter.branchId != null
    //               ? this.filter.branchId.branchId
    //               : undefined,
    //           pagesId: pagesId,
    //           "typeMessage[]":
    //             this.filter.typeMessage != null
    //               ? this.filter.typeMessage.code
    //               : undefined,
    //         },
    //       })
    //       .then((response) => {
    //         // if(response.data.data != null) {
    //         this.rowsAccount = response.data.data;
    //         // } else {
    //         this.rowsPage = null;
    //         this.rowsMessage = null;

    //         // }
    //       })
    //       .catch((err) => {
    //         Swal.fire(
    //           appConfig.swal.title.error,
    //           JSON.stringify(err.response.data.message),
    //           appConfig.swal.type.error
    //         );
    //       })
    //       .then(() => {
    //         // this.loading = false;
    //       });
    // },
    selectAllBranch() {
      this.filter.branchId = this.localData;
      this.logData();
    },
    logData() {
      const arr = [];
      this.filter.branchId.forEach((item) => {
        arr.push(item.branchId);
      });
      this.branchIdArr = arr;
    },

    viewImg(img) {
      this.urlImage = img;
    },

    // getDataPage(type) {
    //   // this.loading = true;

    //   useNetw

    //     .get("/api/social-contact/fb-page-account", {
    //       params: {
    //         typePage: type != null ? type : "FB",
    //         branchId: this.branchIdArr,
    //         fromDate: this.fromDate,
    //         toDate: this.toDate,
    //       },
    //     })
    //     .then((response) => {
    //       // if(response.data.data != null) {
    //       this.type = type;
    //       this.rowsBranchPages = response.data;
    //       // } else {
    //       this.rowsPage = null;
    //       this.rowsMessage = null;
    //       this.rowsAccount = null;
    //       // this.getDataSrc();
    //       // }
    //     })
    //     .catch((err) => {
    //       Swal.fire(
    //         appConfig.swal.title.error,
    //         JSON.stringify(err.response.data.message),
    //         appConfig.swal.type.error
    //       );
    //     })
    //     .then(() => {
    //       // this.loading = false;
    //     });
    // },

    onlyUnique(value, index, self) {
      return self.indexOf(value) === index;
    },

    chatUsername(name) {
      this.username = name;
      this.usermessage = "Hello";

      this.chatMessagesData = [];
      this.member = null;
      const currentDate = new Date();

      this.chatMessagesData.push({
        name: this.username,
        message: this.usermessage,
        time: currentDate.getHours() + ":" + currentDate.getMinutes(),
      });
    },
    async getLineContent(data) {
      const content = JSON.parse(data.urlImg);
      const headers = {
        Authorization: `Bearer LpwPkfFLJCQE3tXW+x4LmretsZW4foKrIj40fJhHoeJ4MB7m5cZb1v3WhMGYF+1JTXULycuQkdvqrDVz1sTp8fNIRMVCYppivnTh/Qq4ODYWna2Ul9Edtl/69UM+F6YjImqh+3nrEX/icaMB65N7JAdB04t89/1O/w1cDnyilFU=`,
      };
      //! Axios
      const response = await axios.get(
        `https://api-data.line.me/v2/bot/message/${content.id}/content`,
        {
          responseType: "arraybuffer",
          headers,
        }
      );
      let resultImg;
      if (data.typeAttachments == "image") {
        let blob = new Blob([response.data], {
          type: response.headers["content-type"],
        });
        let image = URL.createObjectURL(blob);

        resultImg = image;
      }
      return resultImg;
    },
    // getDataLinehander(){
    //   this.getDataLine()
    // },
  },
};
</script>

<style scoped>
a:hover {
  background-color: yellow;
}
</style>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-10 mb-3 position-relative">
        <code> * </code> สาขา:
        <multiselect
          @input="logData()"
          v-model="filter.branchId"
          :options="localData"
          label="nameTh"
          :show-labels="false"
          :multiple="true"
          :searchable="true"
          :loading="isLoading"
          :internal-search="false"
          :clear-on-select="false"
          :close-on-select="false"
          :limit-text="limitText"
          :max-height="600"
          :show-no-results="false"
          :hide-selected="true"
          track-by="branchId"
        >
        </multiselect>
      </div>
      <div class="col-2">
        <label for=""></label><br />
        <button
          class="btn btn-primary"
          @click="selectAllBranch()"
          :disabled="this.filter.branchId.length == this.localData.length"
        >
          เลือกทุกสาขา
        </button>
      </div>
      <div class="col-4 mb-3 position-relative">
        <code> * </code> ประเภทการติดต่อ:
        <multiselect
          v-model="filter.typeMessage"
          label="type"
          type="search"
          :options="optionTypeMessage"
          :show-labels="false"
          open-direction="bottom"
          placeholder=""
        >
          <!-- @input="getDataPage()" -->
          <span slot="noResult">ไม่พบข้อมูล</span>
        </multiselect>
      </div>
      <div class="col-md-3 col-sm-6">
        <div class="mb-3 position-relative">
          <code>* </code>ข้อมูลจากวันที่ :
          <date-picker
            v-model="fromDate"
            :first-day-of-week="1"
            format="YYYY-MM-DD"
            value-type="format"
            lang="en"
          >
          </date-picker>
        </div>
      </div>
      <div class="col-md-3 col-sm-6">
        <div class="mb-3 position-relative">
          <code>* </code>ถึงวันที่ :
          <date-picker
            v-model="toDate"
            :first-day-of-week="1"
            format="YYYY-MM-DD"
            value-type="format"
            lang="en"
          >
          </date-picker>
        </div>
      </div>
      <div class="col-md-auto">
        <div class="mb-3 position-relative">
          <code></code> <br />
          <button class="btn btn-primary" @click="getAllPages()">ค้นหา</button>
        </div>
      </div>
    </div>

    <div class="col-12 col-sm-12 col-md-12">
      <div class="card mb-0">
        <b-tabs justified class="nav-tabs-custom">
          <b-tab>
            <template v-slot:title>
              <i class="uil uil-facebook-messenger font-size-20"></i>
              <span class="d-none d-sm-block">Facebook</span>
            </template>
          </b-tab>

          <b-tab>
            <template v-slot:title>
              <i class="uil uil-line font-size-20"></i>
              <span class="d-none d-sm-block">LINE</span>
            </template>
          </b-tab>
        </b-tabs>
      </div>
    </div>

    <div class="d-lg-flex mb-4">
      <div class="chat-leftsidebar card">
        <!-- //! Start -->
        <!-- <div class="p-3 px-4">
          <h5 class="font-size-16 mb-3">
            <i class="uil uil-user me-1"></i> Pages
            <hr />
          </h5>
          <div
            class="media"
            v-for="(itemPage, index) in rowsBranchPages"
            :key="index"
          >
            <div class="media-body">
              <h5 class="font-size-16 mt-0 mb-1">
                <a href="#" class="text-dark" @click="getDataAccount(itemPage.id , itemPage.pageName)">
                  {{ itemPage.pageName }} 
                  <i
                    class="mdi mdi-circle text-success align-middle font-size-10 ms-1"
                  ></i>
                </a>
              </h5>
            </div>

            <div>
              <b-dropdown
                variant="white"
                class="chat-noti-dropdown"
                toggle-class="py-0"
                menu-class="dropdown-menu-end"
                right
              >
                <template v-slot:button-content>
                  <i class="uil uil-ellipsis-h"></i>
                </template>
                <b-dropdown-item href="#">Profile</b-dropdown-item>
                <b-dropdown-item href="#">Edit</b-dropdown-item>
                <b-dropdown-item href="#">Add Contact</b-dropdown-item>
                <b-dropdown-item href="#">Setting</b-dropdown-item>
              </b-dropdown>
            </div>
          </div>
        </div>  -->
        <!-- //! End -->

        <div class="pb-5">
          <simplebar style="max-height: 400px" id="chat-list">
            <div class="p-8 border-top">
              <div>
                <div class="float-end"></div>
                <h5 class="font-size-16 mb-3">
                  <i class="uil uil-user me-1"></i> Contacts
                </h5>

                <ul
                  class="list-unstyled chat-list group-list"
                  v-for="(page, index) in rowsBranchPages"
                  :key="index"
                >
                  <hr />
                  <h5 class="font-size-16 mb-3 text-center">
                    <!-- {{ data }} -->
                    &nbsp; <b>{{ page.pageName }}</b>
                  </h5>
                  <hr />
                  <li
                    v-for="(user, index) in page.users"
                    :key="index"
                    :class="{ active: userName == user.psId }"
                  >
                    <a @click="getUserMessage(user, true)">
                      <div class="media align-items-center">
                        <div class="avatar-xs me-3">
                          <span
                            v-if="!user.profileImage"
                            class="avatar-title rounded-circle bg-soft-primary text-primary"
                            >U</span
                          >
                          <img
                            v-else
                            :src="user.profileImage"
                            class="avatar-title rounded-circle bg-soft-primary text-primary"
                          />
                        </div>

                        <div class="media-body">
                          <h5 class="font-size-14 mb-0">
                            {{ user.name != null ? user.name : "Unknown" }}
                            {{
                              user.familyName != null ? user.familyName : "User"
                            }}
                          </h5>
                        </div>
                      </div>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </simplebar>
        </div>
      </div>
      <!-- end chat-leftsidebar -->

      <div class="w-100 user-chat mt-4 mt-sm-0 ms-lg-1">
        <div class="card">
          <div class="px-lg-2 chat-users">
            <div class="chat-conversation p-3">
              <vue-easy-lightbox
                :visible="visible"
                :imgs="allImg"
                :index="index"
                @hide="handleHide"
              ></vue-easy-lightbox>
              <simplebar
                style="max-height: 455px"
                id="containerElement"
                ref="current"
              >
                <ul class="list-unstyled mb-0">
                  <li class="chat-day-title" v-if="nowDate != toDate">
                    <div class="title">{{ toDate }}</div>
                  </li>
                  <li class="chat-day-title" v-else>
                    <div class="title">ล่าสุด</div>
                  </li>
                  <li
                    v-for="(data, indexData) in rowsMessage"
                    :key="indexData"
                    :class="{ right: `${data.isPageSend}` === 'true' }"
                  >
                    <div class="conversation-list">
                      <div class="ctext-wrap">
                        <div class="ctext-wrap-content">
                          <h5 class="font-size-14 conversation-name">
                            <a
                              href="#"
                              class="text-dark"
                              v-if="data.firstName != null"
                              >{{ data.firstName }}</a
                            >
                            <a href="#" class="text-dark" v-else>{{
                              pageName
                            }}</a>
                          </h5>
                          <!-- {{ data}} -->
                          <p class="mb-0" v-if="data.attachments == null">
                            {{ data.message }}
                          </p>
                          <div
                            v-for="(dataAudio, index) in data.attachments"
                            :key="'audio' + index"
                          >
                            <audio controls v-if="dataAudio.type === 'audio'">
                              <source
                                :src="dataAudio.payload.url"
                                type="audio/ogg"
                              />
                              <source
                                :src="dataAudio.payload.url"
                                type="audio/mpeg"
                              />
                            </audio>
                            <!-- <a
                              :href="dataAudio.payload.url"
                              class="mb-0"
                              v-if="dataAudio.type === 'audio'"
                              >คลิปเสียง</a
                            > -->
                          </div>
                          <div
                            v-for="(dataFile, index) in data.attachments"
                            :key="'file' + index"
                          >
                            <a
                              :href="dataFile.payload.url"
                              class="mb-0"
                              v-if="dataFile.type === 'file'"
                              >ดาวน์โหลดไฟล์</a
                            >
                          </div>
                          <!-- dddddddddddddddddddddddddddddddd -->
                          <div class="popup-gallery">
                            <div
                              v-for="(dataAttachments,
                              index) in data.attachments"
                              :key="'img' + index"
                              class="float-start m-2"
                              @click="() => showImg(indexData, index)"
                            >
                              <!-- height="150"
                            class="d-block" -->
                              <img
                                :src="dataAttachments.payload.url"
                                v-if="
                                  dataAttachments.type === 'image' &&
                                    dataAttachments.payload.sticker_id == null
                                "
                                width="150"
                                height="150"
                                class="d-block"
                              />
                            </div>
                            <!-- <vue-easy-lightbox
                              :visible="visible"
                              :imgs="allImg"
                              :index="index"
                              @hide="handleHide"
                            ></vue-easy-lightbox> -->
                          </div>
                          <!-- <p
                            v-for="(dataAttachments, index) in data.attachments"
                            :key="index"
                          >
                            <img
                              :src="dataAttachments.payload.url"
                              width="200"
                              height="150"
                              alt=""
                              v-if="dataAttachments.type === 'image'"
                              v-b-modal.modalImg
                              @click="viewImg(data.urlImg)"
                            />
                          </p> -->
                          <div
                            v-for="(dataSticker, index) in data.attachments"
                            :key="'sticker' + index"
                          >
                            <img
                              :src="dataSticker.payload.url"
                              alt=""
                              v-if="dataSticker.payload.sticker_id != null"
                            />
                          </div>
                          <div
                            v-for="(dataVideo, index) in data.attachments"
                            :key="'video' + index"
                          >
                            <video
                              width="250"
                              height="200"
                              controls
                              v-if="dataVideo.type === 'video'"
                            >
                              <source
                                :src="dataVideo.payload.url"
                                type="video/mp4"
                              />
                              <!-- <source :src="movie.ogg" type="video/ogg" /> -->
                            </video>
                          </div>
                          <div
                            v-for="(dataFallback, index) in data.attachments"
                            :key="'fallback' + index"
                          >
                            <a
                              :href="dataFallback.payload.url"
                              target="_blank"
                              class="mb-0"
                              v-if="dataFallback.type === 'fallback'"
                              >{{ data.message }}</a
                            >
                          </div>
                          <div
                            v-for="(dataLocation, index) in data.attachments"
                            :key="'location' + index"
                          >
                            <div
                              id="map"
                              v-if="dataLocation.type === 'location'"
                              style="height: 200px; width: 250px;"
                            ></div>
                            <br />
                          </div>
                          <audio
                            controls
                            v-if="
                              data.attachments === 'audio' &&
                                data.type === 'LINE'
                            "
                          >
                            <source
                              :src="getLineContent(data)"
                              type="audio/x-m4a"
                            />

                            Your browser does not support the audio tag.
                          </audio>
                          <!-- <iframe :src="getLineContent(data)" v-if="
                              data.typeAttachments === 'audio' &&
                                data.type === 'LINE'
                            " ></iframe> -->

                          <!-- <a
                            :href="getLineContent(data)"
                            class="mb-0"
                            v-if="
                              data.typeAttachments === 'audio' &&
                                data.type === 'LINE'
                            "
                            >คลิปเสียง</a
                          > -->
                          <imgChatComponent
                            v-if="
                              data.typeAttachments === 'image' &&
                                data.type === 'LINE'
                            "
                            :dataImg="data"
                          ></imgChatComponent>

                          <div
                            v-if="
                              data.typeAttachments === 'text' &&
                                data.type === 'LINE'
                            "
                          >
                            {{ data.message }}
                          </div>
                          <div
                            v-if="
                              data.typeAttachments === 'sticker' &&
                                data.type === 'LINE'
                            "
                          >
                            ** ส่งสติกเกอร์ **
                          </div>
                        </div>

                        <b-dropdown class="align-self-start" variant="white">
                          <template v-slot:button-content>
                            <i class="uil uil-ellipsis-v"></i>
                          </template>
                          <a class="dropdown-item" href="#">Copy</a>
                          <a class="dropdown-item" href="#">Save</a>
                          <a class="dropdown-item" href="#">Forward</a>
                          <a class="dropdown-item" href="#">Delete</a>
                        </b-dropdown>
                      </div>
                      <span
                        class="mx-4"
                        style="font-size: 9px; color: gray; margin-top: 0"
                      >
                        {{ formatDate(data.webhookTimestamp) }}
                      </span>
                    </div>
                  </li>
                </ul>
              </simplebar>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-modal ref="modalImg" id="modalImg" hide-footer hide-header centered>
      <div class="row align-items-center">
        <img :src="urlImage" alt="" />
      </div>
    </b-modal>
  </Layout>
</template>
