<template>
  <div>
    <img
      :src="url"
      height="400"
      alt=""
      v-b-modal.modalImgCompo
     @click="viewImgCompo(url)"
    />
    <b-modal ref="modalImgCompo" id="modalImgCompo" hide-footer hide-header centered>
      <div class="row align-items-center">
        <img :src="urlImageCompo" alt="" />
      </div>
    </b-modal>
  </div>
</template>
<script>
import axios from "axios";
export default {
  name: "imgChatComponent",
  props: ["dataImg"],
  data() {
    return {
        url:"",
        urlImageCompo:'',
    }
  },
  created(){
      this.getLineContent();
  },
  methods: {
    async getLineContent() {
      
      const data = this.dataImg;
      console.log(data);
      const content = JSON.parse(data.urlImg);
      const headers = {
        Authorization: `Bearer ${data.verifyToken}`,
      };
      //! Axios
      const response = await axios.get(
        `https://api-data.line.me/v2/bot/message/${content.id}/content`,
        {
          responseType: "arraybuffer",
          headers,
        }
      );
      if (data.typeAttachments == "image") {
        // console.log(`data:image/png;base64, ${response.data}`);
        // console.log(response.data);

        let blob = new Blob([response.data], {
          type: response.headers["content-type"],
        });
        let image = URL.createObjectURL(blob);
        this.url = image;
        console.log(this.url);
      }
    },
    viewImgCompo(url){
      this.urlImageCompo = url;
    }
    // click () {
    //     this.$emit('click');
    // }
  },
};
</script>
